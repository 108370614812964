export const APPLICATION_ID = "DT1FRH8HXE";
export const API_KEY = "a7ed77937679ddce485b17da4cd219f3";
export const API_KEY_GOOGLE_MAPS = "AIzaSyAfaynThk-6tdlKNO9HuPexl58n7r3zklg";

/* api */
export const URL_BASE_API = "https://poketmenu-backend-109495223745.us-central1.run.app"; //"https://poketmenu-backend-109495223745.us-central1.run.app";
/* export const URL_BASE_API =
  window.location.hostname == "localhost"
    ? "http://localhost:3030" :
    "https://poketmenu-backend-109495223745.us-central1.run.app"; */
export const URL_BASE = URL_BASE_API;
/* Default Settings */
export const URL_AUTHENTICATION = "/authentication";

export const URL_S3 = "https://menuapps3.s3.amazonaws.com";
export const s3PathImageHandrer = "https://d1gup81n3r8p82.cloudfront.net";
export const URL_S3_SERVER = URL_BASE;
export const SIGIN_S3 = URL_S3_SERVER + "/s3Client/sign";

export const BUCKET = "menuapps3";
export const ACCESS_KEY_ID = "AKIAUA3FK3BGT6F7DDP6";
export const SECRET_ACCESS_KEY = "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";
export const EMPTY_SEARCH_TEXT = "-- Limpiar Búsqueda --";

export const permissions = ["admin", "superadmin", "visor"];

export const URL_DEFAULT_AVATAR = "/images/avatar.svg";
export const DEFAULT_IMAGE = "/images/avatar.svg";
export const LOGO_COLOR = "/images/logo-color.svg";
export const LOGO_WHITE = "/images/logo-white.svg";
export const IMAGE_404 = "/images/404.svg";
export const DEFAULPAYMENTS = "/images/visa.png";
export const DEFAULSHOPPING = "/images/shopping.svg";

export const extensions = ["png", "jpg", "jpeg", "jfif"];
export const colors = [
  "transparent",
  "#0079bf",
  "#d29034",
  "#519839",
  "#b04632",
  "#89609e",
  "#cd5a91",
  "#4bbf6a",
  "#00aecc",
  "#828b91"
];
export const colors_trello = [
  "transparent",
  "#0079bf",
  "#d29034",
  "#519839",
  "#b04632",
  "#89609e",
  "#cd5a91",
  "#4bbf6a",
  "#00aecc",
  "#828b91"
];
export const colors_favro = [
  "transparent",
  "#6f63f4",
  "#2196f3",
  "#19c3d8",
  "#56b475",
  "#a9df4d",
  "#ffea30",
  "#ffa117",
  "#f5276d",
  "#795548",
  "#707f8f"
];
